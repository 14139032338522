module.exports = [{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"className":"gatsby-remark-autolink","maintainCase":true,"removeAccents":true},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590,"showCaptions":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Seelay","short_name":"Seelay","start_url":"/","description":"Multi-skilled Product Designer from India, Passionate about blending creativity with data to shape intuitive, future-ready products that align with both people's needs and business goals.","background_color":"#FFFFFF","theme_color":"#FF2222","display":"standalone","icon":"src/static/s-logo-red-maskable.svg","lang":"en","orientation":"portrait-primary","screenshots":[{"src":"/screenshots/darkmode.png","sizes":"1280x720","type":"image/png","platform":"narrow","label":"Dark Mode"},{"src":"/screenshots/lightmode.png","sizes":"1280x720","type":"image/png","platform":"narrow","label":"Light Mode"}],"categories":["portfolio","design","development","art","opensource"],"shortcuts":[{"name":"Open Blog","short_name":"Blog","description":"Read some blogs.","url":"/blog","icons":[{"src":"maskable/icon-blog.png","sizes":"192x192"}]}],"url_handlers":[{"origin":"https://www.seelay.in"},{"origin":"https://seelay.in"}],"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"8030257915c7b3a652c21a80db4dbc3b"},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#ff2222","showSpinner":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
