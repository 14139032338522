// require('typeface-montserrat');
// require('typeface-karla');

// require('prismjs/themes/prism-tomorrow.css')
require('./src/styles/prism-purpleking-dark.css');
require('prismjs/plugins/line-numbers/prism-line-numbers.css');

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === `undefined`) {
    await import(`intersection-observer`);
  }
};
export function onServiceWorkerUpdateReady() {
  window.location.reload(true);
}

export const onRouteUpdate = ({ location }) => {
  if (process.env.NODE_ENV !== 'production') {
    return null;
  }

  const pagePath = location
    ? location.pathname + location.search + location.hash
    : undefined;
  setTimeout(() => {
    if (typeof gtag === 'function') {
      gtag('event', 'page_view', { page_path: pagePath });
    }
  }, 100);
};
